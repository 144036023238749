import React, { useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { DefaultPageContainer as PageContainer } from "@layout";
import { ProjectMaskCard } from "@molecules";
import { HeroSimple } from "@organisms";

const ProjectsPage = ({ data }) => {
  const { 
    hero,
    projects,
    meta,
  } = {...data};
  
  return (
    <PageContainer meta={meta}>
      <HeroSimple {...hero} />
      <div className="space-y-12 mb-12">
        {projects?.map((project, i) => {
          return (
            <ProjectMaskCard {...project} index={i} key={i} />
          )
        })}
      </div>
    </PageContainer>
  );
};

ProjectsPage.defaultProps = {};

ProjectsPage.propTypes = {};

export default ProjectsPage;
