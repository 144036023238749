import React, { useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { DefaultPageContainer as PageContainer } from "@layout";
import { 
  HeroServices,
  ProcessServices,
  PhilosophyServices,
  WorkingWithUsServices,
  QuoteContainer,
  ProjectsGrid,
  FullWidth,
} from "@organisms";

const PostPage = ({ data }) => {
  const { 
    hero,
    process,
    philosophy,
    workingWithUs,
    quote,
    projects,
    fullWidth,
    meta,
  } = {...data};
  
  return (
    <PageContainer meta={meta}>
      <HeroServices {...hero} />
      <ProcessServices {...process} />
      <PhilosophyServices {...philosophy} />
      <WorkingWithUsServices {...workingWithUs} />
      <QuoteContainer quote={quote} />
      <ProjectsGrid projects={projects} />
      <FullWidth {...fullWidth} />
    </PageContainer>
  );
};

PostPage.defaultProps = {};

PostPage.propTypes = {};

export default PostPage;
