import React, { useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Container, Text } from "@atoms";
import { DefaultPageContainer as PageContainer } from "@layout";
import { Share } from "@molecules";
import { HeroPost, ContentBuilder, Sidebar } from "@organisms";

const PostPage = ({ data }) => {
  const { 
    title,
    hero,
    slug,
    meta,
    url,
    contentBuilder,
    sidebar,
  } = {...data};

  return (
    <PageContainer meta={meta}>
      <div className="bg-white text-black mt-20 pt-24 h-full">
        <Container variant="xl" className="mb-12 md:grid grid-cols-12 gap-12">
          <div className="col-span-full md:col-span-8 lg:col-span-7">
            <HeroPost {...hero} />
            <Share url={url} color="text-blue" small />
            <ContentBuilder slug={slug} blocks={contentBuilder} />
          </div>

          <div className="col-span-full md:col-span-4">
            <div className="hidden md:block mb-8">
              <Share url={url} color="text-blue" />
            </div>
            <Sidebar blocks={sidebar} />
          </div>

          <div className="col-span-full md:col-span-8 lg:col-span-7">
            <Share url={url} color="text-blue" />
          </div>
        </Container>
      </div>
    </PageContainer>
  );
};

PostPage.defaultProps = {};

PostPage.propTypes = {};

export default PostPage;
