import React, { useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { DefaultPageContainer as PageContainer } from "@layout";
import { ManifestoIntro } from "@molecules";
import { ManifestoContainer } from "@organisms";
import { useAppState } from "@state/state";

const ManifestoPage = ({ data }) => {
  const intro = data?.manifesto.filter( b => {
    if (b !== null) {
      return b.type === "intro";
    }
  })[0];
  const { manifesto, footer } = data;

  const [{ manifesto: app }, dispatch] = useAppState();
  const { introPlayed } = app;

  return (
    <PageContainer meta={data.meta}>
      <ManifestoIntro {...intro} />
      {introPlayed && (
        <ManifestoContainer manifestoSlides={manifesto} footerSlides={footer} />
      )}
    </PageContainer>
  );
};

ManifestoPage.defaultProps = {};

ManifestoPage.propTypes = {};

export default ManifestoPage;
