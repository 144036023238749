import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { DefaultPageContainer as PageContainer } from "@layout";
import { HeaderWithProjects } from "@organisms";
import { useAppState } from "@state/state";

const ContactPage = ({ data }) => {
  const { 
    thankYou,
    meta,
  } = {...data};

  return (
    <PageContainer meta={meta}>
      <HeaderWithProjects {...thankYou} />
    </PageContainer>
  );
};

ContactPage.defaultProps = {};

ContactPage.propTypes = {};

export default ContactPage;
