import React, { useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { DefaultPageContainer as PageContainer } from "@layout";
import { Hero, ProjectsHome, QuotesHome, PostsHome, ManifestoCtaHome } from "@organisms";

const HomePage = ({ data }) => {
  const { 
    hero, 
    selectProjects,
    testimonials,
    insights,
    manifesto,
    meta,
  } = {...data};

  return (
    <PageContainer meta={meta}>
      <Hero {...hero} />
      <ManifestoCtaHome {...manifesto} />
      <ProjectsHome {...selectProjects} />
      <QuotesHome {...testimonials} />
      {insights.hide !== true && (<PostsHome {...insights} />)}
    </PageContainer>
  );
};

HomePage.defaultProps = {};

HomePage.propTypes = {};

export default HomePage;
