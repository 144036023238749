import React, { useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { DefaultPageContainer as PageContainer } from "@layout";
import { HeroSimple, FullWidth, MediaEvents, MediaAnnouncements } from "@organisms";

const MediaPage = ({ data }) => {
  const { 
    hero,
    events,
    announcements,
    fullWidth,
    meta,
  } = {...data};

  return (
    <PageContainer meta={meta}>
      <HeroSimple {...hero} />
      <MediaAnnouncements announcements={announcements} />
      {events.hide !== true && (<MediaEvents {...events} />)}
      {fullWidth.hide !== true && (<FullWidth {...fullWidth} />)}
    </PageContainer>
  );
};

MediaPage.defaultProps = {};

MediaPage.propTypes = {};

export default MediaPage;
