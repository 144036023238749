import React, { useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { DefaultPageContainer as PageContainer } from "@layout";
import { HeroSimple, PostsContainer } from "@organisms";

const PostsPage = ({ data }) => {
  const { 
    hero,
    posts,
    meta,
  } = {...data};

  return (
    <PageContainer meta={meta}>
      <HeroSimple {...hero} />
      <PostsContainer posts={posts} />
    </PageContainer>
  );
};

PostsPage.defaultProps = {};

PostsPage.propTypes = {};

export default PostsPage;
