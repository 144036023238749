import React, { useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { DefaultPageContainer as PageContainer } from "@layout";
import { 
  Hero, 
  QuoteContainer, 
  StatsProject, 
  ProjectsGrid, 
  BenefitsProject 
} from "@organisms";

const ProjectPage = ({ data }) => {
  const { 
    hero,
    details,
    benefits,
    quote,
    projects,
    meta,
  } = {...data};
  
  return (
    <PageContainer meta={meta}>
      <Hero {...hero} />
      <StatsProject {...details} />
      <BenefitsProject benefits={benefits} />
      {quote.quote && (<QuoteContainer quote={quote}/>)}
      <ProjectsGrid projects={projects} />
    </PageContainer>
  );
};

ProjectPage.defaultProps = {};

ProjectPage.propTypes = {};

export default ProjectPage;
