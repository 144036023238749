/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage, resolvePage } from "@dataResolvers";
import { Page as PageTemplate } from "@pageTemplates";

export const query = graphql`
query ($slug: [String]) {
  craft {
    entry(section: "page", slug: $slug) {
      ... on Craft_page_pages_Entry {
        #Meta
        uid
        url
        slug
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        #Hero
        heroHeading
        heroBackgroundImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        heroDescription
        #Page Builder
        pageBuilder {
          ... on Craft_pageBuilder_scrollingContent_BlockType {
            type:typeHandle
            uid
            cards {
              ... on Craft_cards_BlockType {
                heading
                description
                processDescription
                processInformation {
                  ... on Craft_processInformation_TableRow {
                    label
                    content
                  }
                }
                color
              }
            }
          }
          ... on Craft_pageBuilder_stickyContent_BlockType {
            type: typeHandle
            uid
            heading
            stickyCopy: copy {
              ... on Craft_copy_BlockType {
                heading
                copy
                items {
                  ... on Craft_items_TableRow {
                    label
                    content
                    full
                  }
                }
              }
            }
            image {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
          ... on Craft_pageBuilder_imageWithCopy_BlockType {
            type: typeHandle
            uid
            heading
            subhead
            copy
            linkField {
              url
              text
            }
            image {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            imageLocation
          }
          ... on Craft_pageBuilder_selectProjects_BlockType {
            type: typeHandle
            uid
            heading
            subhead
            projects {
              title
              url
              ... on Craft_project_project_Entry {
                title
                heroDescription
                heroBackgroundImage {
                  width
                  height
                  hasFocalPoint
                  slug
                  status
                  title
                  focalPoint
                  url
                }
                projectCategories {
                  ... on Craft_projectCategories_Category {
                    title
                    slug
                  }
                }
              }
            }
          }
          ... on Craft_pageBuilder_quote_BlockType {
            type: typeHandle
            uid
            quoteContent
            author
            authorImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
          ... on Craft_pageBuilder_sideHeader_BlockType {
            type: typeHandle
            uid
            heading
            description
            knockoutColor
          }
          ... on Craft_pageBuilder_fullWidth_BlockType {
            type: typeHandle
            uid
            heading
            subhead
            linkField {
              url
              text
            }
            backgroundImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
          ... on Craft_pageBuilder_copy_BlockType {
            type: typeHandle
            uid
            copy
          }
          ... on Craft_pageBuilder_quote_BlockType {
            type: typeHandle
            uid
            quoteContent
            author
            authorImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
          ... on Craft_pageBuilder_image_BlockType {
            type: typeHandle
            uid
            caption
            image {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
          ... on Craft_pageBuilder_video_BlockType {
            type: typeHandle
            uid
            videoUrl
            caption
          }
          ... on Craft_pageBuilder_team_BlockType {
            type: typeHandle
            uid
            heading
            subhead
            team: teamMember {
              ... on Craft_teamMember_BlockType {
                teamMemberName
                jobTitle
                headshot {
                  width
                  height
                  hasFocalPoint
                  slug
                  status
                  title
                  focalPoint
                  url
                }
                large
              }
            }
          }
        }
      }
    }
  }
}
`;

const previewQuery = `
query ($slug: [String]) {
  craft {
    entry(section: "page", slug: $slug) {
      ... on Craft_page_pages_Entry {
        #Meta
        uid
        url
        slug
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        #Hero
        heroHeading
        heroBackgroundImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        heroDescription
        #Page Builder
        pageBuilder {
          ... on Craft_pageBuilder_scrollingContent_BlockType {
            type:typeHandle
            uid
            cards {
              ... on Craft_cards_BlockType {
                heading
                description
                processDescription
                processInformation {
                  ... on Craft_processInformation_TableRow {
                    label
                    content
                  }
                }
                color
              }
            }
          }
          ... on Craft_pageBuilder_stickyContent_BlockType {
            type: typeHandle
            uid
            heading
            stickyCopy: copy {
              ... on Craft_copy_BlockType {
                heading
                copy
                items {
                  ... on Craft_items_TableRow {
                    label
                    content
                    full
                  }
                }
              }
            }
            image {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
          ... on Craft_pageBuilder_imageWithCopy_BlockType {
            type: typeHandle
            uid
            heading
            subhead
            copy
            linkField {
              url
              text
            }
            image {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            imageLocation
          }
          ... on Craft_pageBuilder_selectProjects_BlockType {
            type: typeHandle
            uid
            heading
            subhead
            projects {
              title
              url
              ... on Craft_project_project_Entry {
                title
                heroDescription
                heroBackgroundImage {
                  width
                  height
                  hasFocalPoint
                  slug
                  status
                  title
                  focalPoint
                  url
                }
                projectCategories {
                  ... on Craft_projectCategories_Category {
                    title
                    slug
                  }
                }
              }
            }
          }
          ... on Craft_pageBuilder_quote_BlockType {
            type: typeHandle
            uid
            quoteContent
            author
            authorImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
          ... on Craft_pageBuilder_sideHeader_BlockType {
            type: typeHandle
            uid
            heading
            description
            knockoutColor
          }
          ... on Craft_pageBuilder_fullWidth_BlockType {
            type: typeHandle
            uid
            heading
            subhead
            linkField {
              url
              text
            }
            backgroundImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
          ... on Craft_pageBuilder_copy_BlockType {
            type: typeHandle
            uid
            copy
          }
          ... on Craft_pageBuilder_quote_BlockType {
            type: typeHandle
            uid
            quoteContent
            author
            authorImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
          ... on Craft_pageBuilder_image_BlockType {
            type: typeHandle
            uid
            caption
            image {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
          ... on Craft_pageBuilder_video_BlockType {
            type: typeHandle
            uid
            videoUrl
            caption
          }
          ... on Craft_pageBuilder_team_BlockType {
            type: typeHandle
            uid
            heading
            subhead
            team: teamMember {
              ... on Craft_teamMember_BlockType {
                teamMemberName
                jobTitle
                headshot {
                  width
                  height
                  hasFocalPoint
                  slug
                  status
                  title
                  focalPoint
                  url
                }
                large
              }
            }
          }
        }
      }
    }
  }
}
`;

const dataResolver = ({ craft }) => {
  return resolvePage(craft.entry)
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
