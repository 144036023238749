import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { DefaultPageContainer as PageContainer } from "@layout";
import { HeroContact, ContactForm } from "@organisms";

const ContactPage = ({ data }) => {
  const { 
    hero,
    contact,
    meta,
  } = {...data};

  return (
    <PageContainer meta={meta}>
      <HeroContact {...hero} />
      <ContactForm {...contact} />
    </PageContainer>
  );
};

ContactPage.defaultProps = {};

ContactPage.propTypes = {};

export default ContactPage;
