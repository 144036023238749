import React, { useState } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Container, Text } from "@atoms";
import { DefaultPageContainer as PageContainer } from "@layout";
import { HeroServices, PageBuilder } from "@organisms";

const DefaultPage = ({ data }) => {
  const { 
    title,
    hero,
    slug,
    meta,
    url,
    pageBuilder,
  } = {...data};

  return (
    <PageContainer meta={meta}>
      <HeroServices {...hero} removePadding />
      <PageBuilder slug={slug} blocks={pageBuilder} />
    </PageContainer>
  );
};

DefaultPage.defaultProps = {};

DefaultPage.propTypes = {};

export default DefaultPage;
