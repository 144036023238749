import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { DefaultPageContainer as PageContainer } from "@layout";
import { 
  HeroServices,
  ProcessServices,
  TextWithImage,
  CrewAbout,
  JobsAbout,
} from "@organisms";
import { Meta } from "@atoms";

const ContactPage = ({ data }) => {
  const { 
    hero,
    purpose,
    principles,
    crew,
    careers,
    meta,
  } = {...data};

  return (
    <PageContainer meta={meta}>
      <HeroServices {...hero} />
      <div className="relative -mt-96">
        <ProcessServices processes={purpose} stepNumberOnly />
      </div>
      <TextWithImage {...principles} right />
      <div id="team">
        <CrewAbout {...crew} />
      </div>
      <JobsAbout {...careers} />
      <Meta {...data.meta} />
    </PageContainer>
  );
};

ContactPage.defaultProps = {};

ContactPage.propTypes = {};

export default ContactPage;
